
import { Component, Vue, Watch } from "vue-property-decorator";
import { User, ServicePointBalance, LogicCashTodayBalance } from "@/entities";
import { Getter } from "vuex-class";
import BalancesDashboard from "./BalancesDashboard.vue";
import EstablishmentsDashboard from "./EstablishmentsDashboard.vue";
import { BalanceItem, Establishment } from "./dashboard.type";
import {
  PSidebar,
  PButton,
  PFormSelectOrder,
  SelectType,
  SelectOrderType,
  PFormInputTextSearch,
  PFormSelect,
} from "@/common/components";
import { TranslateResult } from "vue-i18n";
import DateTimeMixin from "@/mixins/datetime";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import VueMethods from "@/vue-methods";

@Component({
  mixins: [DateTimeMixin, VueMethods],
  components: {
    BalancesDashboard,
    EstablishmentsDashboard,
    PSidebar,
    PButton,
    PFormSelectOrder,
    PFormInputTextSearch,
    PFormSelect,
    PIcon,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any */
export class DashboardPage extends Vue {
  @Getter("getLoggedUser") loggedUser?: User;
  servicePointBalances: ServicePointBalance[] = [];
  logicCashTodayBalances: LogicCashTodayBalance[] = [];
  isFirstSort = false;
  showEstablishment = false;
  searchValue = "";
  @Watch("searchValue", { immediate: true, deep: true })
  onChangeSearchValue(): void {
    this.paginate.offset = 0;
  }
  img = "";
  imgProsegur = "https://emazwegenkindstg03.blob.core.windows.net/img/entity/a0e11657-085a-4a68-aa19-2c53a8c5a7e6.svg";
  isActiveEstablishment = false;
  establishments: Establishment[] = [];
  establishmentFilter = "";
  @Watch("establishmentFilter", { immediate: true, deep: true })
  onChangeEstablishmentFiltere(): void {
    this.paginate.offset = 0;
  }
  filterValue: any = "";
  @Watch("filterValue", { immediate: true, deep: true })
  onChangeFilterValue(): void {
    this.paginate.offset = 0;
  }
  paginate: { limit: number; offset: number } = { limit: 50, offset: 0 };
  optionsFilter: SelectType[] = [
    {
      text: this.$t("dashboardPage.filterIsEarlyValueTrue") + "",
      value: { text: this.$t("dashboardPage.filterIsEarlyValueTrue"), key: "isEarlyValue", value: true },
    },
    {
      text: this.$t("dashboardPage.filterIsEarlyValueFalse") + "",
      value: { text: this.$t("dashboardPage.filterIsEarlyValueFalse"), key: "isEarlyValue", value: false },
    },
    // {
    //   text: this.$t("dashboardPage.filterIsLogicCashToday") + "",
    //   value: { text: this.$t("dashboardPage.filterIsLogicCashToday"), key: "type", value: "Logic" },
    // },
    // {
    //   text: this.$t("dashboardPage.filterIsServiceCashToday") + "",
    //   value: { text: this.$t("dashboardPage.filterIsServiceCashToday"), key: "type", value: "Point" },
    // },
  ];
  orderValue: SelectOrderType = { selected: "name", orderDirection: "ASC" };
  optionsOrder: SelectType[] = [
    { text: this.$t("dashboardPage.orderLastTransaction") + "", value: "lastTransactionDate" },
    { text: this.$t("dashboardPage.orderDeviceFillPercentage") + "", value: "deviceFillPercentage" },
    { text: this.$t("dashboardPage.orderName") + "", value: "name" },
  ];
  created(): void {
    const endpointVersion = this.$route.meta?.endpointVersion as string;
    const fetchServicePointBalances =
      endpointVersion === "v2"
        ? this.$services.servicePointBalance.fetchServicePointBalancesV2()
        : this.$services.servicePointBalance.fetchServicePointBalances();
    Promise.all([fetchServicePointBalances, this.$services.logicCashTodayBalance.fetchLogicCashTodayBalances()]).then(
      (resp) => {
        this.servicePointBalances = resp[0].servicePointBalances;
        this.logicCashTodayBalances = resp[1].logicCashTodayBalances;
        this.isFirstSort = false;
        this.respondToVisibility(document.getElementById("showMore"), (visible: boolean) => {
          if (visible) {
            this.paginate.offset += this.paginate.limit;
          }
        });
      }
    );
    if (this.loggedUser?.entities.length == 1) {
      this.img = this.loggedUser?.entities[0].imageLink;
    }
    this.validationImg();
  }
  get numberOfServicePoints(): number {
    if (this.serviceBalances) {
      return this.serviceBalances.filter((b) => b && b.type == "Point").length;
    } else {
      return 0;
    }
  }
  get totalNumberOfServicePoints(): number {
    if (this.servicePointBalances) {
      return this.servicePointBalances.length;
    } else {
      return 0;
    }
  }
  get numberOfLogicCashToday(): number {
    if (this.serviceBalances) {
      return this.serviceBalances.filter((b) => b && b.type == "Logic").length;
    } else {
      return 0;
    }
  }
  get totalNumberOfLogicCashToday(): number {
    if (this.logicCashTodayBalances) {
      return this.logicCashTodayBalances.length;
    } else {
      return 0;
    }
  }
  get serviceBalances(): BalanceItem[] {
    if (this.servicePointBalances?.length) {
      let returnTemp: BalanceItem[] = [];
      this.establishments = [];
      this.logicCashTodayBalances.forEach((pl) => {
        const bal: BalanceItem = { ...pl, type: "Logic", name: pl.logicCashTodayName, entityName: "" };
        returnTemp.push(bal);
        this.pushEstablishment(pl.centerName, pl.centerName);
      });
      this.servicePointBalances.forEach((pb) => {
        returnTemp.push({
          ...pb,
          type: "Point",
          name: pb.servicePointName,
          deviceCode: pb.deviceCode,
          isEarlyValue: pb.isEarlyValue || false,
        });
        this.pushEstablishment(pb.centerName, pb.centerName);
      });
      if (this.filterValue.key) {
        returnTemp = returnTemp.filter((ba) => ba[this.filterValue.key] === this.filterValue.value);
      }
      if (this.establishmentFilter != "") {
        returnTemp = returnTemp.filter((ba) => ba.centerName === this.establishmentFilter);
      }
      if (this.searchValue != "") {
        returnTemp = returnTemp.filter(
          (ba) =>
            ba.name.toUpperCase().includes(this.searchValue.toUpperCase()) || ba.deviceCode?.includes(this.searchValue)
        );
      }
      if (this.orderValue.selected != "") {
        returnTemp = returnTemp.sort((x, y) => {
          return this.sortLocal(x, y, this.orderValue.selected, this.orderValue.orderDirection);
        });
      }
      return this.localPaginate(returnTemp);
    } else {
      return [];
    }
  }

  localPaginate(items: BalanceItem[]): BalanceItem[] {
    return items.slice(0, this.paginate.offset + this.paginate.limit);
  }

  get greeting(): string {
    const currentHour = new Date().getHours();
    if (currentHour < 13) return "greeting.morning";
    if (currentHour < 19) return "greeting.afternoon";
    return "greeting.evening";
  }
  get nameSelectedEstablishment(): string | TranslateResult | undefined {
    if (this.establishmentFilter != "") {
      this.isActiveEstablishment = true;
      return this.establishments.find((es) => es.id === this.establishmentFilter)?.name;
    } else {
      this.isActiveEstablishment = false;
      return this.$t("dashboardPage.establishments");
    }
  }
  pushEstablishment(establishmentID: string, establishment: string): void {
    const estaTemp = this.establishments.find((e) => e.id === establishmentID);
    if (estaTemp) {
      estaTemp.num++;
    } else {
      this.establishments.push({ name: establishment, id: establishmentID, num: 1 });
    }
  }
  sortLocal(x: any, y: any, key: string, direction: string): number {
    if (key === "lastTransactionDate") {
      const result = this.compareDates(x[key], y[key]);
      return direction === "ASC" ? result * -1 : result;
    }
    if (direction === "ASC") {
      if (x[key] < y[key]) {
        return -1;
      }
      if (x[key] > y[key]) {
        return 1;
      }
      return 0;
    } else if (direction === "DESC") {
      if (x[key] < y[key]) {
        return 1;
      }
      if (x[key] > y[key]) {
        return -1;
      }
      return 0;
    } else {
      return 0;
    }
  }
  validationImg(): string {
    if (this.loggedUser?.entities && this.loggedUser?.entities.length == 1) {
      this.img = this.loggedUser?.entities[0].imageLink;
    } else {
      this.img = this.imgProsegur;
    }
    return this.img;
  }
  handleImageError(event: any) {
    event.target.src = require("@/assets/images/imago-prosegur@3x.png");
  }
}
export default DashboardPage;
