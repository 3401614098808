import { render, staticRenderFns } from "./DashboardPage.vue?vue&type=template&id=58faa4ad&scoped=true&"
import script from "./DashboardPage.vue?vue&type=script&lang=ts&"
export * from "./DashboardPage.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardPage.vue?vue&type=style&index=0&id=58faa4ad&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58faa4ad",
  null
  
)

export default component.exports