
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { mapGetters } from "vuex";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import PDropDownContainer from "../../look/PDropDownContainer/PDropDownContainer.vue";
import { User } from "@/entities";

@Component({
  name: "sidebar",
  components: {
    PIcon,
    PDropDownContainer,
  },
})
export default class Sidebar extends Vue {
  @Getter("getUrl") getUrl!: string;
  @Getter("getLoggedUser") loggedUser!: User;
  @Getter("getIsSignedIn") getIsSignedIn!: boolean;

  public activeLinkColor = "#FFD102";
  public inactiveLinkColor = "#4C4C4C";

  get hasOrders():boolean{
    if(this.getIsSignedIn && false){
      return this.loggedUser?.email.includes("@prosegur");
    }
    return false;
  };
  msgActive = true;
  showSubMenu = true;
  titleSubMenu = "";
  menu: any = [
    { name: "dashboard", parent: "dashboard" },
    { name: "transactions", parent: "transactions" },
    { name: "transactions-alerts", parent: "transactions" },
    { name: "orders", parent: "orders" },
    { name: "ordersEmployees", parent: "ordersEmployees" },
  ];

  get currentPath(): string {
    return new URL(this.getUrl).pathname;
  }

  get subMenuComp(): any[] {
    const subMenu = this.menu.find((me: any) => me.name === this.$route.name);
    if (subMenu) {
      this.titleSubMenu = this.$t("menu." + subMenu.parent) + "";
      return this.menu.filter((me: any) => me.parent === subMenu.parent);
    } else {
      return [];
    }
  }

  classMenuActive(nameRouter: string): string {
    const subMenu = this.menu.find((me: any) => me.name === this.$route.name);
    if (subMenu && subMenu.parent === nameRouter) {
      return "menuTopItemActive_Sidebar";
    } else {
      return "";
    }
  }

  classSubMenuActive(nameRouter: string): string {
    if (this.$route.name === nameRouter) {
      return "subMenuItemActive_Sidebar";
    } else {
      return "";
    }
  }

  logout = (): void => {
    Vue.$auth.signoutRedirect();
  };
}
