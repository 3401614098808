import { RawDeviceDetails } from "@/entities";

export class DeviceDetails {
  readonly id: string;
  readonly deviceName: string;
  readonly deviceCode: string;
  readonly deviceManufacturer: string;
  readonly deviceModel: string;
  readonly deviceType: string;
  readonly deviceIp: string;
  readonly devicePort: number;
  readonly solutionType: string;
  readonly maxNoteCapacity: number;
  readonly maxCoinCapacity: number;
  readonly countryCode: string;
  readonly currencies: string[];
  readonly delegationCode: string;
  constructor(data: RawDeviceDetails | null) {
    this.id = data?.id || "";
    this.deviceName = data?.deviceName || "";
    this.deviceCode = data?.deviceCode || "";
    this.deviceManufacturer = data?.deviceManufacturer || "";
    this.deviceModel = data?.deviceModel || "";
    this.deviceType = data?.deviceType || "";
    this.deviceIp = data?.deviceIp || "";
    this.devicePort = data?.devicePort || 0;
    this.solutionType = data?.solutionType || "";
    this.maxNoteCapacity = data?.maxNoteCapacity || 0;
    this.maxCoinCapacity = data?.maxCoinCapacity || 0;
    this.countryCode = data?.countryCode || "";
    this.currencies = data?.currencies || [];
    this.delegationCode = data?.delegationCode || "";
  }
}
