import { BACKEND_BASE_URL_READS, TransactionService } from "@/services";
import Vue from "vue";
import { RawTransactions, Transactions } from "@/entities";
import { Filter, OrderByFields, PageRequest } from "../backend.types";
import { AxiosRequestConfig } from "axios";

export class TransactionServiceImpl implements TransactionService {
  async fetchTransactions(
    limit: number,
    offset: number,
    sortFields: OrderByFields[],
    filters: Filter[]
  ): Promise<Transactions> {
    const url = `${BACKEND_BASE_URL_READS}/api/v1/me/transactions`;
    const body: PageRequest = { limit, offset, sortFields, filters };
    const { data } = await Vue.$axios.post<RawTransactions>(url, body);
    return new Transactions(data);
  }
  async fetchTransactionsFileExport(
    sortFields: OrderByFields[],
    filters: Filter[],
    fields: { field: string; label: string }[],
    translations: { field: string; translation: { [key: string]: string } }[]
  ): Promise<File> {
    const url = `${BACKEND_BASE_URL_READS}/api/v1/me/transactions/export`;
    const body = { sortFields, filters, fields, translations };
    const responseType: AxiosRequestConfig = { responseType: "blob" };
    const response = await Vue.$axios.post(url, body, responseType);
    const contentType = response.headers["content-type"];
    const filename = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
    const blob = new Blob([response.data as BlobPart], {
      type: contentType,
    });
    return new File([blob], filename);
  }
}
